@import "../../../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";

html {
    scroll-behavior: smooth;

    // Compensate for header height when scrolling to bookmarks
    @include media-breakpoint-up(xl) {
        scroll-padding-top: calc(134px + 20px);
    }
}

// For the product page where we have an in-page navigation
html:has(body .ProductInPageNav) {
    @include media-breakpoint-down(sm) {
        scroll-padding-top: calc(140px + 10px);
    }

    @include media-breakpoint-up(sm) {
        scroll-padding-top: calc(60px + 20px);
    }

    @include media-breakpoint-up(md) {
        scroll-padding-top: calc(53px + 20px);
    }

    @include media-breakpoint-up(xl) {
        scroll-padding-top: calc(134px + 53px + 20px) !important;
    }
}
